import { useState, useEffect, useRef } from 'react';
import GameReborn from '../GameReborn';
import { chatDefault, globalDefault, opponentDefault, tumulte2playersDefault } from '../../Home/Lobby/ConnectionHandlerHost';
import { gameModesInfo } from '../../Commons/GameModes';
import { getCardFromCardData, shuffle } from '../../QRCodeRecognition/Functions';
import { useLanguageContext } from '../../Commons/Translations/languageContext';
import { virtualBoardDefault } from '../Components/GameDefaults';
import cardsData from '../../Commons/Data/cards.json'

export default function SingleplayerGame() {
    const { i18n } = useLanguageContext()
    const [gameData, setGameData] = useState({
        global: {
            gameCount: 0,
            tumulteCards: JSON.parse(JSON.stringify(tumulte2playersDefault)),
            turnCounter: 1,
            gameModeInfo: { ...gameModesInfo["_1v1_standard"] },
            firstPlayerIndex: 0,
            activePlayerIndex: 0,
            turnOrder: ["1", "2"],
            lastPlayedCard: false
        },
        players: {
            "1": JSON.parse(JSON.stringify(opponentDefault)),
            "2": JSON.parse(JSON.stringify(opponentDefault))
        },
        chat: { ...chatDefault }
    })
    console.log(gameModesInfo["_1v1_standard"])

    const playersRef = useRef([
        { connection: null, id: "1", calls: [], stream: false },
        { connection: null, id: "2" }])

    const gameDataRef = useRef(gameData)

    useEffect(() => {
        let tmp = { ...gameDataRef.current }
        tmp.players["1"].powerCountersOpponentsId.hero = "2"
        tmp.players["1"].powerCountersOpponentsId.companion = "2"
        tmp.players["2"].powerCountersOpponentsId.hero = "1"
        tmp.players["2"].powerCountersOpponentsId.companion = "1"
        tmp.players["2"].gameMode = "virtual"
        tmp.players["2"].boardData = getChallengeOpponentDefaultData()

        updateGameData(tmp)
    }, [])

    const challengeDeckList = ["16 ALT_CORE_B_AX_08_C",
        "10 ALT_CORE_B_AX_09_C",
        "3 ALT_CORE_B_AX_25_C",
        "3 ALT_CORE_B_AX_30_C",
        "8 ALT_CORE_B_AX_15_C"].join("\n")

    const getChallengeOpponentDefaultData = () => {
        // Deck data
        let cardIdentifiers = challengeDeckList.split('\n')
        let deck = []

        while (cardIdentifiers.length) {
            let card = cardIdentifiers.shift()
            if (card.length > 1) {
                card = card.split(' ')
                if (card.length == 2) {
                    let cData = cardsData[card[1]]
                    if (cData) {
                        let c = getCardFromCardData(cData, i18n.language)
                        for (let i = 0; i < card[0]; i++) {
                            deck.push({ ...c })
                        }
                    }
                }
            }
        }
        shuffle(deck)
        let virtualBoard = JSON.parse(JSON.stringify(virtualBoardDefault))
        virtualBoard.deckList = [...deck]
        virtualBoard.deck = [...deck]

        return virtualBoard
    }


    const sendGameData = (data) => {
        let tmp = { ...gameDataRef.current }
        if (data.player) {
            tmp.players["1"] = data.player
        }
        if (data.global) {
            tmp.global = data.global
        }
        if (data.newMessage) {
            tmp.chat.messages.push(data.newMessage)
        }

        // Analyze data for possible turn to do
        if (data.global) {
            if (data.global.turnCounter != gameDataRef.current.global.turnCounter) {
                tmp.chat.messages.push({
                    sender: {
                        id: "2",
                        name: "bot"
                    },
                    message: "Starting turn " + data.global.turnCounter
                })
                // Draw X
                let boardData = { ...tmp.players["2"].boardData }
                let deck = boardData.deck
                let hand = boardData.hand
                let mana = boardData.mana
                for (let n = 0; n < 2; n++) {
                    hand.push({...deck.shift()})
                }

                // Ajoute en mana toute carte qu'il ne peut pas jouer
                for (let i = 0; i < hand.length; i++) {
                    if (hand[i].manaCost[0] > mana.length) {
                        mana.push({...hand[i]})
                        hand.splice(i, 1)
                        i--
                    }
                }

                boardData.handSize = hand.length
                tmp.players["2"].boardData = boardData
            }
            if (data.global.activePlayerIndex == 1 && data.global.activePlayerIndex != gameDataRef.current.global.activePlayerIndex) {
                let boardData = { ...tmp.players["2"].boardData }
                tmp.chat.messages.push({
                    sender: {
                        id: "2",
                        name: "bot"
                    },
                    message: "A moi de jouer"
                })

                // Joue la carte la moins cher de sa reserve puis de sa main quand reserve vide
                if(boardData.hand.length > 0) {
                    boardData.heroExpedition.push(boardData.hand[0])
                }


                // Detape tout son mana

                // Passe le tour
                tmp.global.activePlayerIndex = 0

                tmp.players["2"].boardData = boardData
            }
        }

        updateGameData(tmp)
    }

    const updateGameData = (data) => {
        setGameData({ ...data })
        gameDataRef.current = { ...data }
        // mettre le ref dans setgamedata
    }

    return (<GameReborn isHost={true} playersRef={playersRef.current} playerId={"1"} gameDataGlobal={gameData.global} gameDataPlayers={gameData.players} chat={gameData.chat} sendDataToOpponents={(data) => { sendGameData(data) }} endCall={() => { }} />);
}