import React, { useEffect, useRef } from "react";
import Peer from "peerjs";
import WaitingRoom from "./WaitingRoom";
import GameReborn from "../../Game/GameReborn";
import { createEmptyMediaStream, pushToDict, uuidv4 } from "../../QRCodeRecognition/Functions";
import { powerCountersActiveDefault, powerCountersDefault, pseudoDefault, tumulteCardsData } from "../../Game/Components/GameDefaults";
import { gameModesInfo } from "../../Commons/GameModes";
import Draft from "../../Draft/Draft";

const { useState } = require("react")

export const tumulte2playersDefault = [{
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}, {
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}, {
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}]

export const tumulte4playersDefault = [{
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}, {
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}, {
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}, {
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}, {
    data: tumulteCardsData["tumulte-1-1"],
    hasBeenRevealed: false
}]

export const globalDefault = {
    gameCount: 0,
    tumulteCards: [...tumulte2playersDefault],
    turnCounter: 1,
    firstPlayerIndex: 0,
    activePlayerIndex: 0,
    turnOrder: [],
    spectatorId: false,
    gameModeInfo: false,
    lastPlayedCard: false
}

export const opponentDefault = {
    faction: 1,
    gameMode: "undefined",
    boardData: false,
    tumultePositions: [0, 7],
    powerCounters: JSON.parse(JSON.stringify(powerCountersDefault)),
    powerCountersActive: JSON.parse(JSON.stringify(powerCountersActiveDefault)),
    powerCountersOpponentsId: { hero: false, companion: false },
    diceRolls: [],
    userInfo: false
}

export const chatDefault = {
    messages: [
        {
            sender: {
                id: "test",
                name: "YellowPiouPiou"
            }, message: "Hello ! This chat is still Work in Progress. Let me know about any issue."
        },
        {
            sender: {
                id: "test",
                name: "YellowPiouPiou"
            }, message: "If you prefer audio over text, feel free to join the Discord and meet in a game room.\nhttps://discord.gg/ARcY9xeapu"
        }
    ]
}

export default function ConnectionHandlerHost(props) {
    const [isGameReady, setIsGameReady] = useState(false)

    const peerInstance = useRef(null)
    const playersRef = useRef([])
    const [playerCount, setPlayerCount] = useState(0)

    const gameId = useRef("error")

    const [gameData, setGameData] = useState({
        global: { ...globalDefault },
        players: {},
        chat: { ...chatDefault }
    })
    const gameDataRef = useRef(gameData)

    const [playerFaction, setPlayerFaction] = useState(1)
    const roomId = useRef(false)
    const hasGameStarted = useRef(false)

    const [userInfo, setUserInfo] = useState(() => {
        const storedUserName = localStorage.getItem('userName');
        if (storedUserName) {
            return {
                name: storedUserName,
                id: ""
            }
        }
        return {
            name: pseudoDefault.at(pseudoDefault.length * Math.random()),
            id: ""
        }
    })
    const [usersInfo, setUsersInfo] = useState([])
    const usersInfoRef = useRef([])
    const updateUsersInfo = (newInfo) => {
        setUsersInfo(newInfo)
        sendDataToOtherPlayers({ usersInfo: newInfo })
        usersInfoRef.current = newInfo
    }
    const lastDataRecievedTime = useRef(false)

    useEffect(() => {
        if (peerInstance.current == null) {
            roomId.current = uuidv4()
            iniPeer()

            let tmp = { ...gameData }
            tmp.global.gameModeInfo = { ...props.gameModeInfo }
            updateGameData(tmp)
        }
    }, [])

    useEffect(() => {
        const unload = async (e) => {
            closeCall()
        }
        window.addEventListener('beforeunload', unload)
        return () => {
            window.removeEventListener(
                'beforeunload',
                unload,
            );
        };
    }, [])

    const iniPeer = () => {
        const peer = new Peer("exalts-table-lobby-ID-" + roomId.current);
        window.history.replaceState(null, "Exalts Table", "/lobby/" + roomId.current)

        // Listening
        peer.on('open', (id) => {
            console.log('My peer ID is: ' + id);
            playersRef.current.push({ connection: null, id: id, stream: createEmptyMediaStream(), calls: [] })
            setPlayerCount(playersRef.current.length)
            let tmp = [...usersInfo]
            tmp.push({ id: id, data: userInfo, team: 1 })
            updateUsersInfo(tmp)

            peer.on("connection", (conn) => {
                if (playersRef.current.length >= props.gameModeInfo.players) {
                    conn.close()
                    return
                }

                // Handle connection
                conn.on("open", () => {
                    playersRef.current.push({ connection: conn, id: conn.peer, stream: false })
                    setPlayerCount(playersRef.current.length)
                    sendRoomData()
                    console.log("Connection succesfull")
                })
                conn.on("close", () => {
                    if (hasGameStarted.current) {
                        closeCall()
                    } else {
                        playersRef.current.splice(playersRef.current.findIndex(a => a.id == conn.peer), 1)
                        setPlayerCount(playersRef.current.length)
                        let tmp = [...usersInfoRef.current]
                        for (let n = 0; n < tmp.length; n++) {
                            if (tmp[n].id == conn.peer) {
                                tmp.splice(n, 1)
                            }
                        }
                        updateUsersInfo(tmp)
                    }
                    console.log("Connection closed")
                });

                // Handle game data
                conn.on("data", (data) => {
                    if (data.userInfo) {
                        let tmp = [...usersInfoRef.current]
                        let found = false
                        for (let n = 0; n < tmp.length; n++) {
                            if (tmp[n].id == conn.peer) {
                                tmp[n].data = data.userInfo
                                found = true
                            }
                        }
                        if (!found) {
                            tmp.push({ id: conn.peer, data: data.userInfo, team: usersInfoRef.current.length % 2 + 1 })
                        }
                        updateUsersInfo(tmp)
                    } else {
                        handleGameData(data, conn.peer)
                    }
                });


            });

            peer.on('call', (call) => {
                try {
                    call.answer(playersRef.current[0].stream)
                    call.on('stream', (remoteStream) => {
                        for (let i = 1; i < playersRef.current.length; i++) {
                            if (playersRef.current[i].id == call.peer) {
                                playersRef.current[i].stream = remoteStream
                                playersRef.current[0].calls.push(call)
                            }
                        }
                    });
                } catch (error) {
                    console.error(error);
                }
            })
        });

        peerInstance.current = peer;
    }

    const sendRoomData = () => {
        let a = []
        playersRef.current.forEach((ref) => {
            if (ref.id) {
                a.push("" + ref.id)
            }
        })

        let n = 0
        playersRef.current.forEach((ref) => {
            n += 1
            if (ref.connection) {
                ref.connection.send({
                    roomData: {
                        gameModeInfo: props.gameModeInfo,
                        playersIdArray: n < playersRef.current.length ? a : []
                    }
                })
            }
        })
    }

    // DATA

    const sendDataToOtherPlayers = (data) => {
        playersRef.current.forEach((ref) => {
            if (ref.connection) {
                console.log("Sending to " + ref.id, data)
                ref.connection.send(data)
            }
        })
    }

    const handleGameData = (data, senderId) => {
        if (senderId == playersRef.current[0].id) {
            console.log("ALARM")
            return
        }
        if (data.gameData) {
            let tmp = { ...gameDataRef.current }
            if (data.gameData.global) {
                if (gameDataRef.current.global.gameModeInfo.modeId == "_draft") {
                    tmp.global = { ...data.gameData.global }
                } else {
                    if (lastDataRecievedTime.current && Date.parse(lastDataRecievedTime.current) > Date.parse(data.time)) { return }
                    lastDataRecievedTime.current = data.time
                    tmp.global = {
                        gameCount: data.gameData.global.gameCount,
                        tumulteCards: data.gameData.global.tumulteCards,
                        turnCounter: data.gameData.global.turnCounter > gameDataRef.current.global.turnCounter || data.gameData.global.gameCount != gameDataRef.current.global.gameCount ? data.gameData.global.turnCounter : gameDataRef.current.global.turnCounter,
                        firstPlayerIndex: data.gameData.global.firstPlayerIndex,
                        activePlayerIndex: data.gameData.global.activePlayerIndex,
                        turnOrder: data.gameData.global.turnOrder,
                        spectatorId: data.gameData.global.spectatorId,
                        gameModeInfo: data.gameData.global.gameModeInfo,
                        lastPlayedCard: data.gameData.global.lastPlayedCard
                    }
                }
            }
            if (data.gameData.player) {
                tmp.players[senderId] = data.gameData.player
            }
            if (data.gameData.newMessage) {
                let tmp = { ...gameDataRef.current }
                tmp.chat.messages.push(data.gameData.newMessage)
                updateGameData(tmp)
            }
            updateGameData(JSON.parse(JSON.stringify(tmp)))
        }
    }

    const sendGameData = (data) => {
        sendDataToOtherPlayers({ time: new Date(), gameData: data })
        if (data.player) {
            let tmp = { ...gameDataRef.current }
            tmp.players[playersRef.current[0].id] = data.player
            updateGameData(tmp)
        }
        if (data.global) {
            let tmp = { ...gameDataRef.current }
            tmp.global = data.global
            updateGameData(tmp)
        }
        if (data.newMessage) {
            let tmp = { ...gameDataRef.current }
            tmp.chat.messages.push(data.newMessage)
            updateGameData(tmp)
        }
    }

    const closeCall = () => {
        if (gameData.global.gameModeInfo.modeId == "_draft") { return }
        if (playersRef.current && playersRef.current[0].stream) {
            const tracks = playersRef.current[0].stream.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        }

        peerInstance.current.destroy()
        playersRef.current = []

        setIsGameReady(false)
        props.setIsLobbyCreated(false)
    }

    const startGame = () => {
        let tmp = { ...gameDataRef.current }
        let tmpTurnOrder = []
        let n = 0
        playersRef.current.forEach((playerRef) => {
            let opData = JSON.parse(JSON.stringify(opponentDefault))
            if (props.gameModeInfo.modeId == "_1v1_standard") {
                opData.powerCountersOpponentsId.hero = playersRef.current[(n + 1) % 2].id
                opData.powerCountersOpponentsId.companion = playersRef.current[(n + 1) % 2].id
            } else if (props.gameModeInfo.modeId == "_1v1_standard_observer") {
                if (n == 0) {
                    opData.powerCountersOpponentsId.hero = playersRef.current[0].id
                    opData.powerCountersOpponentsId.companion = playersRef.current[0].id
                } else {
                    // Flemme de faire les maths
                    if (n == 1) {
                        opData.powerCountersOpponentsId.hero = playersRef.current[2].id
                        opData.powerCountersOpponentsId.companion = playersRef.current[2].id
                    } else {
                        opData.powerCountersOpponentsId.hero = playersRef.current[1].id
                        opData.powerCountersOpponentsId.companion = playersRef.current[1].id
                    }
                }
            } else if (props.gameModeInfo.modeId == "_2v2_standard") {
                // A FAIRE
                let minusOne = n - 1 < 0 ? 3 : n - 1
                let plusOne = n + 1 > 3 ? 0 : n + 1
                opData.powerCountersOpponentsId.hero = "" + playersRef.current[n % 2 == 0 ? minusOne : plusOne].id
                opData.powerCountersOpponentsId.companion = "" + playersRef.current[n % 2 == 0 ? plusOne : minusOne].id
            }
            usersInfo.forEach((info) => {
                if (info.id == playerRef.id) {
                    opData.userInfo = info.data
                }
            })
            pushToDict(tmp.players, playerRef.id, opData)
            tmpTurnOrder.push("" + playerRef.id)
            n += 1
        })
        tmp.players[playersRef.current[0].id].faction = playerFaction
        if (props.gameModeInfo.spectators == 1) {
            tmpTurnOrder.shift()
            tmp.global.spectatorId = playersRef.current[0].id
        } else if (props.gameModeInfo.modeId == "_2v2_standard") {
            // Switch players to keep A/B alternance
            if (usersInfoRef.current[0].team == usersInfoRef.current[1].team) {
                let s = "" + tmpTurnOrder[1]
                tmpTurnOrder[1] = "" + tmpTurnOrder[2]
                tmpTurnOrder[2] = s
                tmp.players[tmpTurnOrder[0]].powerCountersOpponentsId.companion = tmpTurnOrder[1]
                tmp.players[tmpTurnOrder[3]].powerCountersOpponentsId.companion = tmpTurnOrder[2]

                tmp.players[tmpTurnOrder[1]].powerCountersOpponentsId.companion = tmpTurnOrder[0]
                tmp.players[tmpTurnOrder[2]].powerCountersOpponentsId.companion = tmpTurnOrder[3]
            } else if (usersInfoRef.current[1].team == usersInfoRef.current[2].team) {
                let s = "" + tmpTurnOrder[2]
                tmpTurnOrder[2] = "" + tmpTurnOrder[3]
                tmpTurnOrder[3] = s
                tmp.players[tmpTurnOrder[0]].powerCountersOpponentsId.hero = "" + tmpTurnOrder[2]
                tmp.players[tmpTurnOrder[1]].powerCountersOpponentsId.hero = "" + tmpTurnOrder[3]

                tmp.players[tmpTurnOrder[2]].powerCountersOpponentsId.hero = "" + tmpTurnOrder[0]
                tmp.players[tmpTurnOrder[3]].powerCountersOpponentsId.hero = "" + tmpTurnOrder[1]
            }
        }
        tmp.global.turnOrder = tmpTurnOrder

        updateGameData(tmp)
        setIsGameReady(true)
        sendDataToOtherPlayers({ iniGameData: tmp })
        hasGameStarted.current = true
    }

    const updateGameData = (data) => {
        setGameData({ ...data })
        gameDataRef.current = { ...data }
        // mettre le ref dans setgamedata
    }

    const updateUserName = (newName) => {
        let newU = [...usersInfo]
        for (let n = 0; n < newU.length; n++) {
            if (newU[n].id == playersRef.current[0].id) {
                newU[n].data.name = newName
            }
        }
        updateUsersInfo(newU)
        sendDataToOtherPlayers({ usersInfo: usersInfo })
    }

    const switchTeamForUser = (index) => {
        let newU = [...usersInfo]
        newU[index].team = newU[index].team == 1 ? 2 : 1
        updateUsersInfo(newU)
    }

    if (!isGameReady) {
        return (<WaitingRoom currentPlayers={playerCount} isHost={true} startGame={startGame} playerId={playersRef.current[0] ? playersRef.current[0].id : false} roomId={roomId.current} setPlayerFaction={setPlayerFaction} gameModeInfo={gameData.global.gameModeInfo} userInfo={userInfo} usersInfo={usersInfo} updateUserName={updateUserName} switchTeamForUser={switchTeamForUser} />)
    }

    if (isGameReady) {
        if (gameData.global.gameModeInfo.modeId == "_draft") {
            return (<Draft playersData={gameData.players} globalData={gameData.global} sendDataToOpponents={(data) => sendGameData(data)} playerId={playersRef.current[0].id} turnOrder={gameData.global.turnOrder} isHost={true} />)
        }
        return (<GameReborn isHost={true} playersRef={playersRef.current} playerId={playersRef.current[0].id} gameDataGlobal={gameData.global} gameDataPlayers={gameData.players} chat={gameData.chat} sendDataToOpponents={(data) => sendGameData(data)} endCall={() => closeCall()} />)
    }
}